$(function () {
	var headerBreakpoint = window.matchMedia("(min-width: 62em)");

	headerBreakpoint.addListener(closeMenuOnResize);

	closeMenuOnResize(headerBreakpoint);

	$('.jq_menu_status').change(function() {
		if (this.checked) {
			$('html, body').addClass('no-scroll');

			$(document).on('keyup', { status: this }, closeMenuOnEscape);
		} else {
			$('html, body').removeClass('no-scroll');

			$(document).off('keyup', closeMenuOnEscape);
		}
	});

	function closeMenuOnResize(breakpoint) {
		if (breakpoint.matches) {
			$('.jq_menu_status').prop('checked', false).trigger('change');
		}
	}

	function closeMenuOnEscape(event) {
		if (event.keyCode === 27) {
			var args = event.data,
				status = args.status;
			$(status).prop('checked', false).trigger('change');
		}
	}

	if ($('.jq_headroom').length && Headroom.cutsTheMustard) {
		// no support for IE 11 since headroom 0.10.3 https://github.com/WickyNilliams/headroom.js/releases/tag/v0.10.3
		var headroom = new Headroom($('.jq_headroom')[0]);
		headroom.init();
	}
});